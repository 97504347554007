import docsearch from "@docsearch/js";

import "@docsearch/css";
import "../stylesheets/docsearch.css";

docsearch({
  container: "#docsearch",
  appId: "FI43W9TTFS",
  indexName: "genymotion",
  apiKey: "bd584c664f0cdc756bc043b669744925",
  placeholder: "Search all docs",
  debug: false
});
